/*
  Some fixes because we are not applying Chakra's CSS Reset.
  There might be a few things to take from it though:
  https://github.com/chakra-ui/chakra-ui/blob/main/packages/css-reset/src/css-reset.tsx  
*/

button {
  border: none;
  background: none;
}
