$medium-up: 'only screen and (min-width: 40.063em)' !default;

// TODO: Have a lighter variant with no border

.Panel {
  background-color: white;
  padding: 1.875rem 2.8125rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(169, 184, 192, 0.5);
  border: 1px solid #d8d8d8;

  &--noPadding {
    padding: 0;
  }

  &--compactOnMobile {
    padding: 0.9375rem;
    @media #{$medium-up} {
      padding: 1.875rem 2.8125rem;
    }
  }
}
