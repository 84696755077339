$completion-gauge-height: 10px;
$inspire-yellow: #ffdf00;
$inspire-gray: #d8d8d8;
$medium-up: 'only screen and (min-width: 40.063em)' !default;

.completion-gauge {
  width: 90%;
  @media #{$medium-up} {
    width: 70%;
  }
  background-color: #ddd;
  box-shadow: inset 0 1px 0 #ccc;
  position: relative;
  margin: 10px auto 20px auto;
  height: $completion-gauge-height + 1;
  border-radius: 30px;
}

.completion-gauge-completed {
  border-radius: 30px;
  height: $completion-gauge-height;
  transition: all 0.3s ease;
}

.completion-gauge-completed,
.recommendation-gauge-completed {
  position: absolute;
  top: 0;
  background-color: $inspire-yellow;
  box-shadow: 0px 1px 1px darken($inspire-yellow, 10%),
    inset 0px 1px 1px lighten($inspire-yellow, 20%);
}

p.matching-score {
  font-weight: bold;
  color: black !important;
  margin-bottom: 0 !important;
  font-size: 12px;
  line-height: 19px;
}

// recommendation gauge

$gauge-height: 100px;
$gauge-width: 12px;

.sc-gauge {
  width: 100%;
  max-width: 180px;
  padding-bottom: 40px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.sc-outline {
  position: absolute;
  padding-top: calc(50%);
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 160px 160px 0 0;
  overflow: hidden;
  text-align: center;
}

.sc-background {
  position: relative;
  padding-top: calc(50% - 10px);
  margin: 10px 10px 0 10px;
  background-color: $inspire-gray;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  text-align: center;
}

.sc-mask {
  position: absolute;
  top: $gauge-width;
  right: $gauge-width;
  left: $gauge-width;
  height: calc(100% - 15px);
  background-color: #fff;
  border-radius: 150px 150px 0 0;
}

.sc-percentage {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $inspire-yellow;
}

.sc-percentage {
  transform-origin: top center;
}

.sc-left-mask,
.sc-right-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top center;
  background-color: white;
}

.sc-left-mask {
  transform: rotate(20deg);
}

.sc-right-mask {
  transform: rotate(-20deg);
}

.sc-min,
.sc-max {
  width: 22px;
  z-index: 80;
  position: absolute;
}

.sc-emojis {
  position: relative;
  top: -10px;
}

.sc-needle {
  width: calc(50% - 5px);
  left: 5px;
  height: 4px;
  background-color: black;
  position: absolute;
  transition: 0.6s ease-in-out;
  transform-origin: center right;
  transform: rotate(20deg);

  &:before {
    content: ' ';
    display: block;
    background-color: black;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    float: right;
    top: -8px;
    right: -10px;
    position: relative;
  }
}

.sc-min {
  left: 5px;
}
.sc-max {
  right: 5px;
}
