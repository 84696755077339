/* Generated by https://google-webfonts-helper.herokuapp.com */

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/roboto-condensed-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/roboto-condensed-v19-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-condensed-v19-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/roboto-condensed-v19-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-condensed-v19-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/roboto-condensed-v19-latin-300.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-300italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/roboto-condensed-v19-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/roboto-condensed-v19-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-condensed-v19-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/roboto-condensed-v19-latin-300italic.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-condensed-v19-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/roboto-condensed-v19-latin-300italic.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/roboto-condensed-v19-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-condensed-v19-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/roboto-condensed-v19-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-condensed-v19-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/roboto-condensed-v19-latin-regular.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/roboto-condensed-v19-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/roboto-condensed-v19-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-condensed-v19-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/roboto-condensed-v19-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-condensed-v19-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/roboto-condensed-v19-latin-italic.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/roboto-condensed-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/roboto-condensed-v19-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-condensed-v19-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/roboto-condensed-v19-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-condensed-v19-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/roboto-condensed-v19-latin-700.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-700italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/roboto-condensed-v19-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/roboto-condensed-v19-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-condensed-v19-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/roboto-condensed-v19-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-condensed-v19-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/roboto-condensed-v19-latin-700italic.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* Generated from previous INSPIRE Achille font via https://transfonter.org */

@font-face {
  font-family: 'Achille II FY';
  src: url('/fonts/AchilleIIFY-Black.eot');
  src: url('/fonts/AchilleIIFY-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AchilleIIFY-Black.woff2') format('woff2'),
    url('/fonts/AchilleIIFY-Black.woff') format('woff'),
    url('/fonts/AchilleIIFY-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Achille II FY';
  src: url('/fonts/AchilleIIFY-Bold.eot');
  src: url('/fonts/AchilleIIFY-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AchilleIIFY-Bold.woff2') format('woff2'),
    url('/fonts/AchilleIIFY-Bold.woff') format('woff'),
    url('/fonts/AchilleIIFY-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Achille II FY';
  src: url('/fonts/AchilleIIFY-Medium.eot');
  src: url('/fonts/AchilleIIFY-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AchilleIIFY-Medium.woff2') format('woff2'),
    url('/fonts/AchilleIIFY-Medium.woff') format('woff'),
    url('/fonts/AchilleIIFY-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Achille II FY';
  src: url('/fonts/AchilleIIFY-Regular.eot');
  src: url('/fonts/AchilleIIFY-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/AchilleIIFY-Regular.woff2') format('woff2'),
    url('/fonts/AchilleIIFY-Regular.woff') format('woff'),
    url('/fonts/AchilleIIFY-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
